'use strict';

angular.module('matroshkiApp').directive('videoCard', function($rootScope, $uibModal, authService, countryService,$timeout) {
  return {
    templateUrl: appSettings.BASE_URL + 'app/views/video.html',
    restrict: 'AE',
    scope: {
      video: '='
    },
    link: function(scope, elem) {
      var thumbPath = '123'; //video.posterMeta.imageLarge
      var timeout;
      var index = 0;
      var length = scope.video.thumbs ? scope.video.thumbs.length : 0;
      if (length) {
        scope.thumbPath = appSettings.BASE_URL + scope.video.thumbs[1];
      }else {
        scope.thumbPath = thumbPath;
      }

      function doGif() {
        if (timeout) {
          $timeout.cancel(timeout);
        }

        if (index >= length - 1) {
          index = 0;
        }

        scope.thumbPath = appSettings.BASE_URL + scope.video.thumbs[index];

        index++;

        timeout = $timeout(doGif, 1000);
      }

      $(elem).mouseover(function() {

        if (!length) {
          return;
        }

        doGif();
      });

      $(elem).mouseout(function() {
        index = 0;
        if(length) {
          scope.thumbPath = appSettings.BASE_URL + scope.video.thumbs[1];
        }else {
          scope.thumbPath = thumbPath;
        }
        $timeout.cancel(timeout);
      });

      scope.checkIsXrated = function () {
        if ( scope.video.isXrated) {
          authService.getCurrentUser().then(data => {
            if(data && data.data.hasOwnProperty('role')) {
              window.location.href = '/media/video/watch/' + scope.video.seo_url;
              return;
            };

            scope.showSignUpModal();
          })
        }
      }
      countryService.getCountries().then(function(data) {
          scope.countries = data.data.length > 0 ? data.data : null;
      });
      scope.showSignUpModal = function (size) {
        var modalInstance = $uibModal.open({
          animation: true,
          templateUrl: appSettings.BASE_URL + 'app/modals/register-modal/modal.html?v=' + Math.random().toString(36).slice(2),
          controller: 'RegisterInstanceCtrl',
          size: size,
          backdrop: 'static',
          keyboard: false,
          resolve: {
            countries: function() {
              return scope.countries;
            }
          }
        });
        modalInstance.result.then(function (data) {

        });
      };
    }
  };
})